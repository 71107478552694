// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$daxa-primary: mat.define-palette(mat.$indigo-palette);
$daxa-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$daxa-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$daxa-theme: mat.define-light-theme((
  color: (
    primary: $daxa-primary,
    accent: $daxa-accent,
    warn: $daxa-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($daxa-theme);

/* You can add global styles to this file, and also import other style files */
:root {
  --fontFamily: Outfit, sans-serif;
  --daxaColor: #0f79f3;
  --daxaColorOrange: #ef7b14;
  --wizodiaPrimaryColor: #EF7B14;
  --wizodiaSecondaryColor: #112236;
  --primaryColor: #796df6;
  --dangerColor: #e74c3c;
  --infoColor: #00cae3;
  --warningColor: #ffb264;
  --successColor: #2ed47e;
  --blackColor: #475569;
  --whiteColor: #ffffff;
  --bodyColor: #919aa3;
  --transition: .5s;
  --fontSize: 16px;
  --borderBoxShadow: rgba(0, 0, 0, .05) 0px 6px 24px 0px, rgba(0, 0, 0, .08) 0px 0px 0px 1px;
}

*, ::after, ::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: var(--blackColor);
  background-color: #eff3f9;
  font: {
    weight: 400;
    size: var(--fontSize);
    family: var(--fontFamily);
  };
}

a {
  transition: var(--transition);
  color: var(--blackColor);
  text-decoration: none;
  outline: 0 !important;

  &:hover {
    text-decoration: none;
    color: var(--daxaColorOrange) !important;
  }
}

.d-table {
  width: 100%;
  height: 100%;

  &-cell {
    vertical-align: middle;
  }
}

img {
  max-width: 100%;
  height: auto;
}

input {
  font: {
    family: var(--fontFamily) !important;
    size: var(--fontSize);
  };
}

button {
  font: {
    family: var(--fontFamily) !important;
    size: var(--fontSize) !important;
  };
}

.mb-25 {
  margin-bottom: 25px !important;
}

// Utilities CSS

@import "./assets/scss/utilities";

// UI Kit CSS
@import "./assets/scss/ui-kit";

// Typography CSS
@import "./assets/scss/typography";

// RTL CSS
@import "./assets/scss/rtl";

// Dark CSS
@import "./assets/scss/dark";

@import "./utilities";

// UI Kit CSS
@import "./ui-kit";

// Typography CSS
@import "./typography";

// RTL CSS
@import "./rtl";

// Dark CSS
@import "./dark";


/* Max width 767px */
@media only screen and (max-width: 767px) {

  body {
    font-size: 14px;
  }
  .mb-25 {
    margin-bottom: 15px !important;
  }
  button {
    font-size: 14px !important;
  }

}

/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {

  .mb-25 {
    margin-bottom: 20px !important;
  }

}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {

  body {
    font-size: 15px;
  }
  button {
    font-size: 15px !important;
  }

}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
}

/* Min width 1600px */
@media only screen and (min-width: 1600px) {
}

.hide {
  display: none
}

.completed {
  background-color: #ECFDF5 !important;
  color: #10B981 !important;
}

.in-progress {
  background-color: #E5EEF9 !important;
  color: #2567A4 !important;
}

.pending {
  background-color: #FDEFD7 !important;
  color: #E16715 !important;
  border-radius: 4px !important;
}

.blocked {
  background-color: rgba(231, 76, 60, 0.1) !important;
  color: #e74c3c !important;
}

.progress-container {
  width: 100%;
  height: 8px;
  background-color: #f0f0f0;
  margin-bottom: 15px;
  margin-top: 10px;
}

.progress-container-datatable {
  width: 100%;
  height: 4px;
  background-color: #f0f0f0;
  margin-bottom: 15px;
  margin-top: 15px;
}

.progress-container-card {
  width: 100%;
  height: 4px;
  background-color: #FCD2C2;
}

.progress-bar {
  height: 100%;
  background-color: #EF7B14;
  transition: width 0.3s ease-in-out;
}

.button-add {
  background: #000000 !important;
  padding: 11px 35px !important;
  color: #ffff !important;
  border: none !important;
  transition: none !important;
  font-family: Outfit, sans-serif !important;
}

.button-add:hover {
  color: #ffff !important;
}

.button-connexion {
  margin-top: 15px !important;
  background: #EF7B14 !important;
  padding: 11px 35px !important;
  font-size: 25px !important;
  color: #ffff !important;
  font-family: Outfit, sans-serif !important;
  width: 100%;
  border: none !important;
  box-shadow: none !important;
}

.button-valider {
  margin-right: 10px;
  background: #EF7B14 !important;
  padding: 11px 35px !important;
  color: #ffff !important;
  font-family: Outfit, sans-serif !important;
  border: none !important;
  box-shadow: none !important;
}

.button-valider-outline {
  background: #ffff !important;
  padding: 11px 35px !important;
  color: #EF7B14 !important;
  border: 1px solid #EF7B14 !important;
  font-family: Outfit, sans-serif !important;
}

.button-valider:disabled {
  background: rgba(239, 123, 20, 0.38) !important;
  padding: 11px 35px !important;
  color: #ffff !important;
  border: none;
  font-family: Outfit, sans-serif !important;
}

.button-annuler {
  margin-right: 10px;
  background: #000000 !important;
  padding: 11px 35px !important;
  color: #ffff !important;
  font-family: Outfit, sans-serif !important;
}

//
.cr_button-valider {
  margin-right: 10px;
  background: #EF7B14 !important;
  padding: 5px 12px !important;
  color: #ffff !important;
  font-family: Outfit, sans-serif !important;
  border: none !important;
  box-shadow: none !important;
}

.cr_button-valider-outline {
  background: #ffff !important;
  padding: 5px 12px !important;
  color: #EF7B14 !important;
  border: 1px solid #EF7B14 !important;
  font-family: Outfit, sans-serif !important;
}

.cr_button-valider:disabled {
  background: rgba(239, 123, 20, 0.38) !important;
  padding: 5px 12px !important;
  color: #ffff !important;
  border: none;
  font-family: Outfit, sans-serif !important;
}

.cr_button-annuler {
  margin-right: 10px;
  background: #000000 !important;
  padding: 5px 12px !important;
  color: #ffff !important;
  font-family: Outfit, sans-serif !important;
}

.cr_button-supprimer {
  margin-right: 10px;
  background: #ffffff !important;
  padding: 5px 12px !important;
  color: #000000 !important;
  font-family: Outfit, sans-serif !important;
  border: 1px solid #000000 !important;
}

//

/*Faire scroller le datatable*/

.p-datatable-wrapper {
  /*overflow-x: auto;
  overflow-y: hidden;*/
  /*overflow: auto;*/
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #ef7b14;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background: #ef7b14;
  border-radius: 50px;
}

/* Fin Faire scroller le datatable*/


.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mdc-floating-label,
.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mdc-floating-label--float-above {
  color: #EF7B14 !important;
}

.mat-mdc-form-field {
  width: 100%;
  border-radius: 5px;
  --mdc-filled-text-field-caret-color: #EF7B14 !important;
  --mdc-filled-text-field-focus-active-indicator-color: #EF7B14 !important;
  font-family: Outfit, sans-serif !important;
}

.mat-expansion-panel-header {
  font-family: Outfit, sans-serif !important;
}

.mat-expansion-panel-content {
  font-family: Outfit, sans-serif !important;
}

.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  font-family: Outfit, sans-serif !important;
}

.mat-mdc-menu-panel.card-header-menu .mat-mdc-menu-content .mat-mdc-menu-item:hover {
  color: #ef7b14 !important;
}

.datatable-image {
  width: 42px !important;
  height: 42px
}

.profil-image {
  border-radius: 200px !important;
  width: 60px !important;
  height: 60px !important;
}

.profil-image-card {
  border-radius: 200px !important;
  width: 40px !important;
  height: 40px !important;
}

.teams-image {
  border-radius: 200px !important;
  width: 80px !important;
  height: 80px !important;
}

.card-content-datatable {
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.mat-mdc-card.daxa-card .mat-mdc-card-content .mat-mdc-table .mat-mdc-cell {
  padding: 20px !important;
}

.mat-mdc-paginator .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mat-mdc-paginator-page-size-label {
  font-family: Outfit, sans-serif !important;
}

.mat-mdc-paginator .mat-mdc-paginator-range-actions .mat-mdc-paginator-range-label {
  font-family: Outfit, sans-serif !important;
}

.mat-mdc-paginator-page-size-value {
  font-family: Outfit, sans-serif !important;
}

.breadcrumb-card .breadcrumb .breadcrumb-item a i {
  color: #EF7B14 !important;
}

.sidebar-area .sidebar-inner .sidebar-menu .mat-accordion .mat-expansion-panel .mat-expansion-panel-header.mat-expanded .mat-content .mat-expansion-panel-header-title .material-symbols-outlined,
.sidebar-area .sidebar-inner .sidebar-menu .mat-accordion .mat-expansion-panel:has(> .mat-expansion-panel-content > .mat-expansion-panel-body > .sidebar-sub-menu > .sidemenu-item > .sidemenu-link.active) .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-title .material-symbols-outlined,
.sidebar-area .sidebar-inner .sidebar-menu .mat-accordion .mat-expansion-panel:has(> .mat-expansion-panel-content > .mat-expansion-panel-body > .sidebar-sub-menu > .sidemenu-item > .sidemenu-link.active) .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-title .material-symbols-outlined:hover {
  color: var(--wizodiaPrimaryColor) !important;
}

.mat-mdc-card.daxa-card .mat-mdc-card-content .mat-mdc-table .action-info .mat-mdc-button .mdc-button__label i {
  color: #EF7B14 !important;
}

.material-symbols-outlined {
  // color: #EF7B14!important;
  color: var(--whiteColor);
}

.action-info i.material-symbols-outlined {
  color: var(--whiteColor);
  font-size:20px !important;
}

.item-action{
  //color: var(--whiteColor);
  width:20px !important;
  height:20px !important;
}

.step-button-action img{
  //color: var(--whiteColor);
  width:20px !important;
  height:20px !important;
}

.step-button-action img {
    width:20px !important;
    height:20px !important;
    cursor: pointer;
}

.sidebar-area .sidebar-inner .sidebar-menu .mat-accordion .sidebar-menu-link.active i,
.sidebar-area .sidebar-inner .sidebar-menu .mat-accordion .sidebar-menu-link.active i:hover {
  color: #EF7B14 !important;
}

.mat-mdc-card.daxa-card.project-discussion-card[_ngcontent-ng-c408458122] .mat-mdc-card-content[_ngcontent-ng-c408458122] .chat-body[_ngcontent-ng-c408458122] ul[_ngcontent-ng-c408458122] li.right[_ngcontent-ng-c408458122] .message[_ngcontent-ng-c408458122] p[_ngcontent-ng-c408458122] {
  background: #EF7B14 !important;
}

.hoverIcon:hover {
  color: #EF7B14 !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
  font-family: Outfit, sans-serif !important;
}

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: #EF7B14 !important;
}

.mat-form-field-hide-placeholder .mat-mdc-select-placeholder {
  font-family: Outfit, sans-serif !important;
}

.mat-mdc-select {
  font-family: Outfit, sans-serif !important;
}

.mat-mdc-select-arrow {
  color: #EF7B14 !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #EF7B14 !important;
}

.tooltip {
  --mdc-plain-tooltip-container-color: #000000 !important;
  --mdc-plain-tooltip-supporting-text-color: #ffffff !important;
  --mdc-plain-tooltip-supporting-text-font: Outfit, sans-serif !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: #EF7B14 !important;
  font-family: Outfit, sans-serif !important;
}

.mat-mdc-tab.mdc-tab .mdc-tab__text-label {
  font-family: Outfit, sans-serif !important;
}

.mat-tree-node, .mat-nested-tree-node {
  font-family: Outfit, sans-serif!important;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #EF7B14 !important;
  --mat-tab-header-active-label-text-color: var(--daxaColorOrange) !important;
  --mat-tab-header-active-ripple-color: var(--daxaColorOrange) !important;
  --mat-tab-header-inactive-ripple-color: var(--daxaColorOrange) !important;
  --mat-tab-header-active-focus-label-text-color: var(--daxaColorOrange) !important;
  --mat-tab-header-active-hover-label-text-color: var(--daxaColorOrange) !important;
  --mat-tab-header-active-focus-indicator-color: var(--daxaColorOrange) !important;
  --mat-tab-header-active-hover-indicator-color: var(--daxaColorOrange) !important;
}

/* Min height 1600px */
@media only screen and (min-height: 700px) {
  .overflow_content {
    overflow: auto !important;
    max-height: 500px !important;
  }
  .overflow_content_discussion {
    overflow: auto !important;
    max-height: 650px !important;
  }
  .discussion_height {
    max-height: 650px !important;
  }
}

@media only screen and (max-height: 699px) {
  .overflow_content {
    overflow: auto !important;
    max-height: 300px !important;
  }
  .overflow_content_discussion {
    overflow: auto !important;
    max-height: 300px !important;
  }
}


.daxa-badge-attente {
  width: 16px !important;
  height: 16px !important;
  border-radius: 50% !important;
  background-color: #0d8ae7 !important;
}

.daxa-badge-encours {
  width: 16px !important;
  height: 16px !important;
  border-radius: 50% !important;
  background-color: #e0b456 !important;
}

.daxa-badge-termine {
  width: 16px !important;
  height: 16px !important;
  border-radius: 50% !important;
  background-color: rgba(51, 210, 47, 0.47) !important;
}

.MyMediathequeStyle {
  padding: 15px;
  display: inline;
  position: relative;
}

.img1 {
  position: relative;
  top: 0;
  left: 0;
}

.refresh-btn{
  margin-left:10px;
  display:flex;
  justify-content:center;
  align-items: center;
  i{
    color:#475569;
    font-size: 30px;
    cursor:pointer
  }
}


.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: red;
}

.mat-calendar-body-selected{
  background: #EF7B14 !important;
  color: #FFF !important;
}
