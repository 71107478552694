.dark-theme {
    background-color: #131920;
    color: var(--whiteColor);

    // Default Variable
    --mat-ripple-color: rgba(255, 255, 255, 0.1);
    --mat-option-label-text-color: rgba(255, 255, 255, 0.87);
    --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.04);
    --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.04);
    --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.04);
    --mat-optgroup-label-text-color: rgba(255, 255, 255, 0.87);
    --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
    --mat-app-text-color: rgba(255, 255, 255, 0.87);
    --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.54);
    --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
    --mat-form-field-enabled-select-arrow-color: rgba(255, 255, 255, 0.54);
    --mat-form-field-disabled-select-arrow-color: rgba(255, 255, 255, 0.38);
    --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
    --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
    --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
    --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
    --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
    --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
    --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
    --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
    --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
    --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
    --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
    --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
    --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
    --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
    --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
    --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
    --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
    --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
    --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
    --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
    --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
    --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
    --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
    --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
    --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
    --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
    --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
    --mat-menu-item-label-text-color: rgba(255, 255, 255, 0.87);
    --mat-menu-item-icon-color: rgba(255, 255, 255, 0.87);
    --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.04);
    --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.04);
    --mdc-list-list-item-hover-label-text-color: rgba(255, 255, 255, 0.87);
    --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.38);
    --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.38);
    --mdc-list-list-item-focus-label-text-color: rgba(255, 255, 255, 0.87);
    --mdc-list-list-item-label-text-color: rgba(255, 255, 255, 0.87);
    --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.54);
    --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.38);
    --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.38);
    --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.38);
    --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.38);
    --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
    --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
    --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
    --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
    --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
    --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
    --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
    --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.38);
    --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
    --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
    --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.38);
    --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
    --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
    --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.38);
    --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
    --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.38);
    --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.38);
    --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
    --mat-fab-disabled-state-container-color: rgba(255, 255, 255, 0.12);
    --mat-fab-disabled-state-foreground-color: rgba(255, 255, 255, 0.38);
    --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
    --mat-table-header-headline-color: rgba(255, 255, 255, 0.87);
    --mat-table-row-item-label-text-color: rgba(255, 255, 255, 0.87);
    --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
    --mat-badge-disabled-state-text-color: rgba(255, 255, 255, 0.38);
    --mat-bottom-sheet-container-text-color: rgba(255, 255, 255, 0.87);
    --mat-legacy-button-toggle-text-color: rgba(255, 255, 255, 0.38);
    --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
    --mat-legacy-button-toggle-selected-state-text-color: rgba(255, 255, 255, 0.54);
    --mat-legacy-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.26);
    --mat-standard-button-toggle-text-color: rgba(255, 255, 255, 0.87);
    --mat-standard-button-toggle-selected-state-text-color: rgba(255, 255, 255, 0.87);
    --mat-standard-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.26);
    --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(255, 255, 255, 0.87);
    --mat-datepicker-toggle-icon-color: rgba(255, 255, 255, 0.54);
    --mat-datepicker-calendar-body-label-text-color: rgba(255, 255, 255, 0.54);
    --mat-datepicker-calendar-period-button-icon-color: rgba(255, 255, 255, 0.54);
    --mat-datepicker-calendar-navigation-button-icon-color: rgba(255, 255, 255, 0.54);
    --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
    --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.54);
    --mat-datepicker-calendar-date-today-outline-color: rgba(255, 255, 255, 0.38);
    --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(255, 255, 255, 0.18);
    --mat-datepicker-calendar-date-text-color: rgba(255, 255, 255, 0.87);
    --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255, 255, 255, 0.38);
    --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
    --mat-datepicker-range-input-separator-color: rgba(255, 255, 255, 0.87);
    --mat-datepicker-range-input-disabled-state-separator-color: rgba(255, 255, 255, 0.38);
    --mat-datepicker-range-input-disabled-state-text-color: rgba(255, 255, 255, 0.38);
    --mat-datepicker-calendar-container-background-color: white;
    --mat-datepicker-calendar-container-text-color: rgba(255, 255, 255, 0.87);
    --mat-divider-color: rgba(255, 255, 255, 0.12);
    --mat-expansion-container-text-color: rgba(255, 255, 255, 0.87);
    --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
    --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
    --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
    --mat-expansion-header-disabled-state-text-color: rgba(255, 255, 255, 0.26);
    --mat-expansion-header-text-color: rgba(255, 255, 255, 0.87);
    --mat-expansion-header-description-color: rgba(255, 255, 255, 0.54);
    --mat-expansion-header-indicator-color: rgba(255, 255, 255, 0.54);
    --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
    --mat-sidenav-container-text-color: rgba(255, 255, 255, 0.87);
    --mat-sidenav-content-text-color: rgba(255, 255, 255, 0.87);
    --mat-sidenav-scrim-color: rgba(255, 255, 255, 0.6);
    --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
    --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
    --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
    --mat-stepper-header-label-text-color: rgba(255, 255, 255, 0.54);
    --mat-stepper-header-optional-label-text-color: rgba(255, 255, 255, 0.54);
    --mat-stepper-header-selected-state-label-text-color: rgba(255, 255, 255, 0.87);
    --mat-stepper-header-icon-background-color: rgba(255, 255, 255, 0.54);
    --mat-toolbar-container-text-color: rgba(255, 255, 255, 0.87);
    --mat-tree-node-text-color: rgba(255, 255, 255, 0.87);

    // Default
    a {
        color: var(--whiteColor);

        &:hover {
            color: var(--daxaColor);
        }
    }
    textarea, input {
        background-color: #1b232d;
        color: var(--whiteColor);
    }

    // Color
    .bg-white {
        background-color: #1b232d !important;
    }
    .bg-gray {
        background-color: rgba(255, 255, 255, .05) !important;
    }
    .text-black {
        color: var(--whiteColor) !important;
    }
    .text-body {
        color: rgba(255, 255, 255, .50) !important;
    }
    .text-muted {
        color: rgba(255, 255, 255, .35) !important;
    }
    .text-daxa-hover {
        &:hover {
            color: var(--daxaColor) !important;
        }
    }

    // Paragraph
    p {
        color: rgba(255, 255, 255, .50);
    }

    // Headings
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, .display-1, .display-2, .display-3, .display-4, .display-5, .display-6 {
        color: var(--whiteColor);
    }

    // Mat Divider
    .mat-divider {
        --mat-divider-color: rgba(255, 255, 255, 0.09);
    }

    // NG Scroll
    .ng-scrollbar {
        --scrollbar-thumb-color: rgba(255, 255, 255, .1);
    }

    // Mat Card
    .mat-mdc-card {
        --mdc-elevated-card-container-color: #1b232d;
        --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.54);

        &.daxa-card {
            .mat-mdc-card-header {
                .mat-mdc-card-header-text {
                    .mat-mdc-card-title {
                        .search-box {
                            i {
                                color: var(--whiteColor);
                            }
                            .input-search {
                                background: rgba(255, 255, 255, .07);
                                color: var(--whiteColor);

                                &::placeholder {
                                    color: rgba(255, 255, 255, .50);
                                }
                                &:focus {
                                    &::placeholder {
                                        color: transparent;
                                    }
                                }
                            }
                        }
                    }
                    .mat-mdc-card-subtitle {
                        .mat-mdc-button {
                            &.card-header-menu-btn {
                                color: var(--whiteColor);
                            }
                            &.card-header-link-btn {
                                color: var(--whiteColor);
                            }
                        }
                        .search-box {
                            i {
                                color: var(--whiteColor);
                            }
                            .input-search {
                                background: rgba(255, 255, 255, .07);
                                color: var(--whiteColor);

                                &::placeholder {
                                    color: rgba(255, 255, 255, .50);
                                }
                                &:focus {
                                    &::placeholder {
                                        color: transparent;
                                    }
                                }
                            }
                        }
                        .buttons-list {
                            .mat-mdc-button {
                                color: var(--whiteColor);
                                border-color: rgba(255, 255, 255, 0.09);

                                &.active {
                                    border-color: var(--daxaColor);
                                }
                            }
                        }
                        .info-buttons-list {
                            .mat-mdc-button {
                                color: rgba(255, 255, 255, .50);

                                &:hover {
                                    color: var(--daxaColor);
                                }
                            }
                        }
                    }
                }
            }
            .mat-mdc-card-content {
                .apexcharts-xaxistooltip, .apexcharts-yaxistooltip {
                    box-shadow: unset;
                    color: var(--whiteColor);
                    background-color: var(--blackColor);
                }
                .apexcharts-tooltip {
                    box-shadow: unset;
                    background-color: var(--blackColor);

                    * {
                        color: rgba(255, 255, 255, .50);
                    }
                    .apexcharts-tooltip-title {
                        color: var(--whiteColor);
                        background-color: #131920;
                    }
                    .apexcharts-tooltip-series-group {
                        border-color: rgba(255, 255, 255, 0.09);
                        background-color: var(--blackColor) !important;
                    }
                }
                .apexcharts-tooltip-text-goals-value, .apexcharts-tooltip-text-y-value, .apexcharts-tooltip-text-z-value {
                    color: var(--whiteColor);
                }
                .apexcharts-tooltip-rangebar {
                    .series-name {
                        background-color: #131920;
                        color: var(--whiteColor) !important;
                    }
                    .category {
                        color: var(--whiteColor);
                    }
                }
                .apexcharts-gridline, .apexcharts-xaxis-tick, .apexcharts-xaxis line {
                    stroke: rgba(255, 255, 255, .2);
                }
                .apexcharts-grid-borders {
                    line {
                        stroke: rgba(255, 255, 255, .2);
                    }
                }
                .apexcharts-pie-series {
                    path {
                        stroke: #1b232d;
                    }
                }
                .apexcharts-grid-row {
                    fill: transparent;
                }
                .apexcharts-pie {
                    line, circle {
                        stroke: rgba(255, 255, 255, .2);
                    }
                }
                .apexcharts-title-text {
                    fill: var(--whiteColor);
                }
                .apexcharts-menu-icon, .apexcharts-pan-icon, .apexcharts-reset-icon, .apexcharts-selection-icon, .apexcharts-toolbar-custom-icon, .apexcharts-zoom-icon, .apexcharts-zoomin-icon, .apexcharts-zoomout-icon {
                    color: var(--whiteColor);

                    svg {
                        fill: var(--whiteColor);
                    }
                }
                .apexcharts-menu {
                    border-color: var(--blackColor);
                    background-color: var(--blackColor);
                }
                .apexcharts-radialbar-track {
                    path {
                        stroke: rgba(255, 255, 255, 0.09);
                    }
                }
                .apexcharts-text {
                    &.apexcharts-datalabel-value {
                        fill: var(--whiteColor);
                    }
                }
                .apexcharts-annotation-rect, .apexcharts-area-series .apexcharts-area, .apexcharts-area-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events, .apexcharts-gridline, .apexcharts-line, .apexcharts-line-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events, .apexcharts-point-annotation-label, .apexcharts-radar-series path, .apexcharts-radar-series line, .apexcharts-radar-series polygon, .apexcharts-toolbar svg, .apexcharts-tooltip .apexcharts-marker, .apexcharts-xaxis-annotation-label, .apexcharts-yaxis-annotation-label, .apexcharts-zoom-rect {
                    stroke: rgba(255, 255, 255, 0.09);
                }
                .NgxEditor__Wrapper {
                    border-color: rgba(255, 255, 255, 0.09);

                    .NgxEditor__Content {
                        color: var(--whiteColor);

                        .NgxEditor__Placeholder {
                            &::before {
                                color: rgba(255, 255, 255, .50);
                            }
                        }
                    }
                    .NgxEditor {
                        background-color: transparent;
                    }
                    .NgxEditor__MenuItem {
                        .NgxEditor__MenuItem--IconContainer {
                            svg {
                                fill: var(--whiteColor);
                            }
                        }
                        &:hover {
                            background-color: var(--blackColor);
                        }
                    }
                    .NgxEditor__Dropdown {
                        &:hover {
                            background-color: var(--blackColor);
                        }
                        .NgxEditor__Dropdown--DropdownMenu {
                            background-color: var(--blackColor);
                        }
                    }
                    .NgxEditor__Popup {
                        background-color: var(--blackColor);
                    }
                    .NgxEditor__MenuBar {
                        background-color: #131920;
                        border-color: rgba(255, 255, 255, 0.09);
                    }
                    .NgxEditor__Seperator {
                        border-color: rgba(255, 255, 255, 0.09);
                    }
                    .NgxEditor__Popup {
                        .NgxEditor__Popup--FormGroup {
                            input[type=text], input[type=url], input[type=href] {
                                border-color: rgba(255, 255, 255, 0.09);
                            }
                        }
                    }
                }
                .mat-mdc-table {
                    .mat-mdc-header-row {
                        color: var(--whiteColor);
                    }
                    .mat-mdc-header-cell {
                        background-color: #131920;
                        color: var(--whiteColor);
                    }
                    .mat-mdc-row {
                        color: var(--whiteColor);
                    }
                    .mat-mdc-cell {
                        background-color: #1b232d;
                        color: rgba(255, 255, 255, .50);
                        border-color: rgba(255, 255, 255, 0.09);
                    }
                    .mat-mdc-footer-row {
                        color: var(--whiteColor);

                        .mdc-data-table__cell {
                            border-color: rgba(255, 255, 255, 0.09);
                        }
                    }
                    .mat-mdc-no-data-row {
                        td {
                            border-color: rgba(255, 255, 255, 0.09);
                        }
                    }
                    .action-info {
                        .mat-mdc-button {
                            .mdc-button__label {
                                color: var(--daxaColor);
                            }
                            &:not(:first-child) {
                                .mdc-button__label {
                                    color: rgba(255, 255, 255, .50);
                                }
                            }
                        }
                    }
                    &.expandable-rows {
                        .mat-mdc-cell {
                            border-color: rgba(255, 255, 255, 0.09);
                        }
                        tr {
                            &.example-element-row {
                                &:not(.example-expanded-row) {
                                    &:hover {
                                        td {
                                            background: var(--blackColor);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .file-uploader {
                    border-color: rgba(255, 255, 255, 0.09);

                    .upload-input {
                        color: rgba(255, 255, 255, .50);

                        .upload-text {
                            b {
                                color: var(--whiteColor);
                            }
                        }
                        &:hover {
                            color: var(--daxaColor);
                        }
                    }
                    .icon {
                        border-color: rgba(255, 255, 255, 0.2);
                    }
                    file-upload-icon {
                        border-color: rgba(255, 255, 255, 0.2);
                    }
                    .remove-btn {
                        svg {
                            fill: var(--whiteColor);
                        }
                    }
                    .file-info {
                        color: var(--whiteColor);
                    }
                    .remove-btn {
                        color: rgba(255, 255, 255, .50);
                    }
                }
                .table-responsive {
                    &::-webkit-scrollbar-track {
                        background-color: rgba(255, 255, 255, .10);
                    }
                    &::-webkit-scrollbar-thumb {
                        background: var(--blackColor);
                    }
                }
            }
        }
    }

    // Pagination
    .mat-mdc-paginator {
        --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);

        .mat-mdc-paginator-outer-container {
            .mat-mdc-paginator-container {
                .mat-mdc-paginator-page-size-label {
                    color: rgba(255, 255, 255, .50);
                }
                .mat-mdc-select-value-text {
                    color: var(--whiteColor);
                }
                .mat-mdc-select-arrow {
                    color: rgba(255, 255, 255, .50);
                }
            }
        }
        .mat-mdc-paginator-range-actions {
            .mat-mdc-paginator-range-label {
                color: rgba(255, 255, 255, .50);
            }
            .mat-mdc-icon-button {
                border-color: rgba(255, 255, 255, 0.09);
                color: rgba(255, 255, 255, .50);

                .mat-mdc-paginator-icon {
                    fill: rgba(255, 255, 255, .50);
                }
                &[disabled] {
                    .mat-mdc-paginator-icon {
                        fill: var(--mat-paginator-disabled-icon-color);
                    }
                }
            }
        }
    }
    .pagination-list {
        --border-color: rgba(255, 255, 255, 0.09);
        --pagination-active-color: #1b232d;
        --tertiary-bg: var(--blackColor);
        --body-bg: #1b232d;
    }
    .pagination-card {
        .pagination {
            .pagination-item {
                .mat-mdc-button {
                    background-color: #1b232d;
                    color: rgba(255, 255, 255, .50);
                    border-color: rgba(255, 255, 255, 0.09);

                    &.active {
                        border-color: var(--daxaColor);
                        background-color: var(--daxaColor);
                    }
                }
            }
        }
    }

    // Mat Checkbox
    .mat-mdc-checkbox {
        --mdc-form-field-label-text-color: rgba(255, 255, 255, 0.87);
        --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.25);
        --mdc-checkbox-unselected-hover-icon-color: rgba(255, 255, 255, 0.50);
    }

    // Breadcrumb
    .breadcrumb-card {
        .breadcrumb {
            .breadcrumb-item {
                color: rgba(255, 255, 255, .50);

                a {
                    color: rgba(255, 255, 255, .50);

                    &:hover {
                        color: var(--daxaColor);
                    }
                }
                &:before {
                    opacity: .50;
                }
                &:last-child {
                    color: var(--whiteColor);
                }
            }
        }
    }
    .d-breadcrumb {
        --secondary-color: rgba(255, 255, 255, .50);
    }

    // Mat Button
    .mdc-button {
        --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
        --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.38);
        --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
        --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.38);
        --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(255, 255, 255, 0.2), 0px 2px 2px 0px rgba(255, 255, 255, 0.14), 0px 1px 5px 0px rgba(255, 255, 255, 0.12);
        --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
        --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.38);
        --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.38);
        --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);

        &.mat-unthemed {
            --mdc-protected-button-label-text-color: var(--whiteColor);
            --mdc-protected-button-container-color: black;
            --mdc-text-button-label-text-color: var(--whiteColor);
            --mdc-outlined-button-label-text-color: var(--whiteColor);
            --mat-outlined-button-state-layer-color: var(--whiteColor);
            --mdc-filled-button-container-color: black;
            --mdc-filled-button-label-text-color: var(--whiteColor);

            &.daxa {
                --mdc-text-button-label-text-color: var(--daxaColor);
                --mdc-protected-button-container-color: var(--daxaColor);
                --mdc-outlined-button-label-text-color: var(--daxaColor);
                --mat-outlined-button-state-layer-color: var(--daxaColor);
                --mdc-filled-button-container-color: var(--daxaColor);
            }
        }
    }
    .mat-mdc-icon-button {
        --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.38);
    }
    .mdc-fab {
        --mat-fab-disabled-state-foreground-color: rgba(255, 255, 255, 0.38);
        --mat-fab-disabled-state-container-color: rgba(255, 255, 255, 0.12);
    }

    // Mat Button Toggle
    .mat-button-toggle-group {
        --mat-standard-button-toggle-divider-color: rgba(255, 255, 255, 0.09);
        --mat-standard-button-toggle-text-color: rgba(255, 255, 255, 0.87);
        --mat-standard-button-toggle-background-color: #1b232d;
        --mat-standard-button-toggle-selected-state-text-color: rgba(255, 255, 255, 0.87);
        --mat-standard-button-toggle-selected-state-background-color: black;
        --mat-standard-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.26);
        --mat-standard-button-toggle-disabled-state-background-color: black;
        --mat-legacy-button-toggle-text-color: rgba(255, 255, 255, 0.38);
        --mat-legacy-button-toggle-selected-state-text-color: rgba(255, 255, 255, 0.54);
        --mat-legacy-button-toggle-selected-state-background-color: black;
    }

    // Blockquotes
    .blockquote-footer {
        color: rgba(255, 255, 255, .50);
    }

    // Mat Tree
    .mat-tree {
        --mat-tree-container-background-color: #1b232d;
        --mat-tree-node-text-color: rgba(255, 255, 255, 0.87);
    }

    // Carousel
    .owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    background-color: var(--blackColor);
                }
                &:hover, &.active {
                    span {
                        background: var(--daxaColor);
                    }
                }
            }
        }
        .owl-nav {
            [class*=owl-] {
                color: var(--whiteColor);
                background-color: var(--blackColor);

                &:hover {
                    color: var(--whiteColor);
                    background: var(--daxaColor);
                }
            }
        }
    }

    // Mat Chips
    .mat-mdc-standard-chip {
        --mdc-chip-disabled-label-text-color: var(--whiteColor);
        --mdc-chip-label-text-color: var(--whiteColor);
        --mdc-chip-with-icon-icon-color: var(--whiteColor);
        --mdc-chip-with-icon-disabled-icon-color: var(--whiteColor);
        --mdc-chip-with-icon-selected-icon-color: var(--whiteColor);
        --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--whiteColor);
        --mdc-chip-with-trailing-icon-trailing-icon-color: var(--whiteColor);
        --mdc-chip-elevated-container-color: #131920;
        --mdc-chip-elevated-disabled-container-color: #131920;
    }

    // Color Picker
    .color-picker {
        background-color: var(--blackColor);

        input {
            color: var(--whiteColor);
        }
        .hex-text {
            .box {
                div {
                    color: var(--whiteColor);
                }
            }
        }
    }

    // Mat Input
    .mat-mdc-form-field {
        --mdc-icon-button-icon-color: var(--whiteColor);
        --mdc-filled-text-field-input-text-color: var(--whiteColor);
        --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, .50);
        --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
        --mdc-filled-text-field-disabled-container-color: #131920;
        --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
        --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
        --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
        --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
        --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);

        input, textarea {
            background-color: transparent;
        }
        .mat-mdc-text-field-wrapper {
            border-color: rgba(255, 255, 255, 0.15);
        }
    }

    // Mat Calendar
    .mat-calendar {
        --mat-datepicker-toggle-icon-color: rgba(255, 255, 255, 0.54);
        --mat-datepicker-calendar-body-label-text-color: rgba(255, 255, 255, 0.54);
        --mat-datepicker-calendar-period-button-icon-color: rgba(255, 255, 255, 0.54);
        --mat-datepicker-calendar-navigation-button-icon-color: rgba(255, 255, 255, 0.54);
        --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
        --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.54);
        --mat-datepicker-calendar-date-today-outline-color: rgba(255, 255, 255, 0.38);
        --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(255, 255, 255, 0.18);
        --mat-datepicker-calendar-date-text-color: rgba(255, 255, 255, 0.87);
        --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255, 255, 255, 0.38);
        --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
        --mat-datepicker-range-input-separator-color: rgba(255, 255, 255, 0.87);
        --mat-datepicker-range-input-disabled-state-separator-color: rgba(255, 255, 255, 0.38);
        --mat-datepicker-range-input-disabled-state-text-color: rgba(255, 255, 255, 0.38);
        --mat-datepicker-calendar-container-text-color: rgba(255, 255, 255, 0.87);
    }

    // Mat Expansion
    .mat-expansion-panel {
        --mat-expansion-container-background-color: #131920;
        --mat-expansion-container-text-color: rgba(255, 255, 255, 0.87);
        --mat-expansion-header-text-color: rgba(255, 255, 255, 0.87);
        --mat-expansion-header-description-color: rgba(255, 255, 255, 0.54);
        --mat-expansion-header-indicator-color: rgba(255, 255, 255, 0.54);
        --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
        --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
        --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
        --mat-expansion-header-disabled-state-text-color: rgba(255, 255, 255, 0.26);

        &:not([class*=mat-elevation-z]) {
            box-shadow: 0px 3px 1px -2px rgba(255, 255, 255, 0.2), 0px 2px 2px 0px rgba(255, 255, 255, 0.14), 0px 1px 5px 0px rgba(255, 255, 255, 0.12);
        }
    }

    // Mat Radio
    .mat-mdc-radio-button {
        --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.2);
        --mdc-radio-unselected-hover-icon-color: rgba(255, 255, 255, 0.4);

        .mdc-form-field {
            color: var(--whiteColor);
        }
    }

    // Mat List
    .mat-accent {
        .mdc-list-item__start, .mdc-list-item__end {
            --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
            --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
        }
    }
    .mat-mdc-list-option {
        &.mat-accent {
            --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
            --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
        }
    }

    // Mat Progress Bar
    .mat-mdc-progress-bar {
        --mdc-linear-progress-track-color: rgba(255, 255, 255, 0.1);
    }

    // Mat Drawer
    .mat-drawer-container {
        --mat-sidenav-container-background-color: #1b232d;
        --mat-sidenav-content-background-color: var(--blackColor);
    }

    // Mat Slide Toggle
    .mat-mdc-slide-toggle {
        --mdc-switch-unselected-track-color: rgba(255, 255, 255, 0.2);
        --mdc-switch-selected-focus-track-color: rgba(255, 255, 255, 0.2);
        --mdc-switch-selected-hover-track-color: rgba(255, 255, 255, 0.2);
        --mdc-switch-selected-pressed-track-color: rgba(255, 255, 255, 0.2);
        --mdc-switch-selected-track-color: rgba(255, 255, 255, 0.2);
        --mdc-switch-unselected-hover-track-color: rgba(255, 255, 255, 0.4);
        --mdc-switch-unselected-focus-track-color: rgba(255, 255, 255, 0.4);
        --mdc-switch-unselected-pressed-track-color: rgba(255, 255, 255, 0.4);

        .mdc-label {
            color: var(--whiteColor);
        }
    }

    // Mat Tabs
    .mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
        --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.38);
        --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
        --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
        --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
    }

    // Mat Toolbar
    .mat-toolbar {
        --mat-toolbar-container-background-color: var(--blackColor);
    }

    // Popup
    .add-new-task-popup, .add-new-event-popup, .add-another-task-popup, .create-new-folder-popup {
        .popup-dialog {
            .daxa-card {
                .mat-mdc-card-header {
                    border-color: rgba(255, 255, 255, 0.09);
                }
            }
        }
    }

}
