.rtl-enabled {
    direction: rtl;
    text-align: right;

    // Default CSS
    .text-left {
        text-align: right !important;
    }
    .text-right {
        text-align: left !important;
    }

    // _utilities CSS
    .mr-15 {
        margin: {
            right: 0 !important;
            left: 15px !important;
        };
    }

    // Mat Card
    .mat-mdc-card {
        &.daxa-card {
            .mat-mdc-card-header {
                .mat-mdc-card-header-text {
                    .mat-mdc-card-title {
                        .search-box {
                            i {
                                left: auto;
                                right: 13px;
                            }
                            .input-search {
                                padding: {
                                    right: 40px;
                                    left: 15px;
                                };
                            }
                        }
                    }
                    .mat-mdc-card-subtitle {
                        .mat-mdc-button {
                            &.card-header-menu-btn {
                                padding: 0 0 0 18px;

                                &::after {
                                    left: -4px;
                                    right: auto;
                                }
                            }
                        }
                        .search-box {
                            i {
                                left: auto;
                                right: 13px;
                            }
                            .input-search {
                                padding: {
                                    left: 15px;
                                    right: 40px;
                                };
                            }
                        }
                        .daxa-badge {
                            padding: {
                                left: 5px;
                                right: 22.84px;
                            };
                            i {
                                left: auto;
                                right: 5px;
                            }
                        }
                        .buttons-list {
                            .mat-mdc-button {
                                padding: {
                                    left: 12px;
                                    right: 32px;
                                };
                                .mdc-button__label {
                                    i {
                                        left: auto;
                                        right: 10px;
                                    }
                                }
                                &:not(:first-child) {
                                    margin: {
                                        left: 0;
                                        right: 8px;
                                    };
                                }
                            }
                        }
                        .info-buttons-list {
                            .mat-mdc-button {
                                margin: {
                                    left: 0;
                                    right: 18px;
                                };
                                &:first-child {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
            .mat-mdc-card-content {
                .apexcharts-canvas {
                    direction: ltr;
                    text-align: left;
                }
                .daxa-up-down-badge {
                    padding: {
                        left: 5px;
                        right: 22.84px;
                    };
                    i {
                        left: auto;
                        right: 5px;
                    }
                }
                .mat-mdc-table {
                    .mat-mdc-header-cell {
                        text-align: right;
                    }
                    .mat-mdc-cell {
                        text-align: right;
                    }
                    .mat-mdc-footer-row {
                        .mdc-data-table__cell {
                            text-align: right;
                        }
                    }
                    .action-info {
                        .mat-mdc-button {
                            &:not(:first-child) {
                                margin: {
                                    left: 0;
                                    right: 12px;
                                };
                            }
                        }
                    }
                }
            }
        }
    }

    // Pagination
    .mat-mdc-paginator {
        .mat-mdc-paginator-outer-container {
            .mat-mdc-paginator-container {
                .mat-mdc-form-field {
                    margin: 0 10px 0 0;
                }
                .mat-mdc-select-arrow-wrapper {
                    margin: {
                        left: 0;
                        right: 5px;
                    };
                }
            }
        }
        .mat-mdc-paginator-range-actions {
            .mat-mdc-paginator-range-label {
                margin: 0 0 0 15px;
            }
            .mat-mdc-icon-button {
                margin: {
                    left: 0;
                    right: 5px;
                };
                svg {
                    transform: scaleX(-1);
                }
            }
        }
    }
    .pagination-card {
        .pagination {
            .pagination-item {
                &:first-child {
                    margin: {
                        left: 3px;
                        right: 0;
                    };
                }
                &:last-child {
                    margin: {
                        right: 3px;
                        left: 0;
                    };
                }
            }
        }
    }

    // Mat Form Field
    .mat-mdc-form-field {
        text-align: right;
    }
    .mat-mdc-form-field-has-icon-suffix {
        .mat-mdc-text-field-wrapper {
            padding: {
                left: 0;
                right: 15px;
            };
        }
    }

    // Mat Progress
    .mat-mdc-progress-bar {
        text-align: right;

        .mdc-linear-progress__bar, .mdc-linear-progress__bar-inner {
            right: 0;
        }
        .mdc-linear-progress__bar {
            transform-origin: top right;
        }
    }

    // Owl Carousel
    .owl-carousel {
        direction: ltr;
        text-align: left;

        .owl-item {
            direction: rtl;
            text-align: right;
        }
    }

    // Breadcrumb
    .breadcrumb-card {
        .breadcrumb {
            padding-right: 0;

            .breadcrumb-item {
                a {
                    padding: {
                        left: 0;
                        right: 20px;
                    };
                    i {
                        left: auto;
                        right: 0;
                    }
                }
                &:before {
                    left: -14px;
                    right: auto;
                }
                &:first-child {
                    margin: {
                        right: 0;
                        left: 12px;
                    };
                }
                &:last-child {
                    margin: {
                        left: 0;
                        right: 12px;
                    };
                }
            }
        }
    }
    .d-breadcrumb-item {
        &+.d-breadcrumb-item {
            padding: {
                left: 0;
                right: var(--breadcrumb-item-padding-x);
            };
            &::before {
                float: right;
                padding: {
                    right: 0;
                    left: var(--breadcrumb-item-padding-x);
                };
            }
        }
    }

    // Mat Stepper
    .mat-horizontal-stepper-header {
        .mat-step-icon {
            margin: {
                right: 0;
                left: 8px;
            };
        }
    }
    .mat-vertical-stepper-header {
        .mat-step-icon {
            margin: {
                right: 0;
                left: 12px;
            };
        }
    }

    // Mat Tab
    .example-tab-icon {
        margin: {
            right: 0 !important;
            left: 8px;
        };
    }

    // Mat Slider
    .mat-mdc-slider {
        direction: ltr;
        text-align: left;
    }

    // Mat Slide Toggle
    .mat-mdc-slide-toggle {
        .mdc-label {
            margin: {
                left: 0;
                right: 5px;
            };
            padding: {
                left: 0;
                right: 4px;
            };
        }
    }

    // Mat Radio
    .mat-mdc-radio-button {
        .mdc-label {
            padding: {
                left: 0;
                right: 7px;
            };
        }
    }

    // Mat Chips
    .mdc-evolution-chip--with-avatar {
        &.mdc-evolution-chip--with-primary-graphic {
            .mdc-evolution-chip__action--primary {
                padding: {
                    right: 0;
                    left: 12px;
                };
            }
            .mdc-evolution-chip__graphic {
                padding: {
                    left: 8px;
                    right: 4px;
                };
            }
        }
    }
    .mat-mdc-standard-chip {
        &.mdc-evolution-chip--with-trailing-action {
            .mdc-evolution-chip__action--primary {
                padding: {
                    left: 0;
                    right: 12px;
                };
            }
        }
        &.mdc-evolution-chip--with-primary-graphic {
            .mdc-evolution-chip__action--primary {
                padding: {
                    left: 12px;
                    right: 0;
                };
            }
        }
    }

}

/* Max width 767px */
@media only screen and (max-width : 767px) {

    .rtl-enabled {

        // Mat Card
        .mat-mdc-card {
            &.daxa-card {
                .mat-mdc-card-header {
                    .mat-mdc-card-header-text {
                        .mat-mdc-card-subtitle {
                            .mat-mdc-button {
                                &.card-header-menu-btn {
                                    padding: 0 0 0 15px;
                                }
                            }
                            .buttons-list {
                                .mat-mdc-button {
                                    margin: {
                                        right: 0;
                                        left: 8px;
                                    };
                                }
                            }
                            .info-buttons-list {
                                .mat-mdc-button {
                                    margin: {
                                        left: 0;
                                        right: 15px;
                                    };
                                }
                            }
                        }
                    }
                }
            }
        }

        // Pagination
        .mat-mdc-paginator {
            .mat-mdc-paginator-outer-container {
                .mat-mdc-paginator-container {
                    .mat-mdc-form-field {
                        margin: 0 5px 0 0;
                    }
                }
            }
            .mat-mdc-paginator-range-actions {
                .mat-mdc-paginator-range-label {
                    margin: 0 0 0 10px;
                }
            }
        }

    }

}

/* Min width 576px to Max width 767px */
@media only screen and (min-width : 576px) and (max-width : 767px) {}

/* Min width 768px to Max width 991px */
@media only screen and (min-width : 768px) and (max-width : 991px) {}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width : 992px) and (max-width : 1199px) {}

/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {}

/* Min width 1600px */
@media only screen and (min-width: 1600px) {}
