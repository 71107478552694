// Display
.d-flex {
    display: flex !important;
}
.d-none {
    display: none !important;
}
.d-inline {
    display: inline !important;
}
.d-inline-block {
    display: inline-block !important;
}
.d-block {
    display: block !important;
}
.d-grid {
    display: grid !important;
}
.d-table {
    display: table !important;
}
.d-table-cell {
    display: table-cell !important;
}
.d-table-row {
    display: table-row !important;
}
.d-inline-flex {
    display: inline-flex !important;
}

// Align items
.align-items-start {
    align-items: start !important;
}
.align-items-center {
    align-items: center !important;
}
.align-items-end {
    align-items: end !important;
}

// Justify content
.justify-content-center {
    justify-content: center !important;
}
.justify-content-between {
    justify-content: space-between !important;
}
.justify-content-end {
    justify-content: end !important;
}

// Flex
.flex-column {
    flex-direction: column !important;
}
.flex-grow-1 {
    flex-grow: 1 !important;
}
.flex-wrap {
    flex-wrap: wrap !important;
}

// Text alignment
.text-center {
    text-align: center !important;
}
.text-left {
    text-align: left !important;
}
.text-right {
    text-align: right !important;
}

// Margin
.ml-auto {
    margin-left: auto !important;
}
.mr-auto {
    margin-right: auto !important;
}
.m-0 {
    margin: 0 !important;
}
.mr-0 {
    margin-left: 0 !important;
}
.mr-0 {
    margin-right: 0 !important;
}
.mt-0 {
    margin-top: 0 !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.mb-5 {
    margin-bottom: 5px !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.mb-12 {
    margin-bottom: 12px !important;
}
.mb-13 {
    margin-bottom: 13px !important;
}
.mb-15 {
    margin-bottom: 15px !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.mt-5 {
    margin-top: 5px !important;
}
.mt-10 {
    margin-top: 10px !important;
}
.mt-15 {
    margin-top: 15px !important;
}
.mt-20 {
    margin-top: 20px !important;
}
.mr-15 {
    margin-right: 15px !important;
}

.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px !important;
}

// Color
.bg-white {
    background-color: var(--whiteColor) !important;
}
.bg-black {
    background-color: var(--blackColor) !important;
}
.bg-daxa {
    background-color: var(--daxaColor) !important;
}
.bg-warning {
    background-color: var(--warningColor) !important;
}
.bg-success {
    background-color: var(--successColor) !important;
}
.bg-info {
    background-color: var(--infoColor) !important;
}
.bg-primary {
    background-color: var(--primaryColor) !important;
}
.bg-danger {
    background-color: var(--dangerColor) !important;
}
.bg-transparent {
    background-color: transparent !important;
}
.bg-gray {
    background-color: #f4f6fc !important;
}
.bg-gradient {
    background: linear-gradient(180deg, #5C4EDB 0%, #796DF6 100%) !important;
}
.bg-orange-wizodia{
  background: #EF7B14 !important;
}
.text-white {
    color: var(--whiteColor) !important;
}
.text-black {
    /*color: var(--blackColor) !important;*/
    color: #6E8FA2 !important;
}
.text-daxa {
    color: var(--daxaColor) !important;
}
.text-warning {
    color: var(--warningColor) !important;
}
.text-success {
    color: var(--successColor) !important;
}
.text-info {
    color: var(--infoColor) !important;
}
.text-primary {
    color: var(--primaryColor) !important;
}
.text-danger {
    color: var(--dangerColor) !important;
}
.text-body {
    color: var(--bodyColor) !important;
}
.text-muted {
    color: #8e8da1 !important;
}
.text-daxa-hover {
    &:hover {
        /*color: var(--daxaColor) !important;*/
        color: var(--daxaColorOrange) !important;
    }
}

// Border
.border-none {
    border: none !important;
}

// Border Radius
.border-radius {
    border-radius: 10px !important;
}
.border-top-radius {
    border-radius: 10px 10px 0 0 !important;
}
.border-bottom-radius {
    border-radius: 0 0 10px 10px !important;
}
.rounded-circle {
    border-radius: 50% !important;
}
.rounded-pill {
    border-radius: 50rem !important;
}

// Padding
.p-0 {
    padding: 0 !important;
}
.pl-0 {
    padding-left: 0 !important;
}
.pr-0 {
    padding-right: 0 !important;
}
.pt-0 {
    padding-top: 0 !important;
}
.pb-0 {
    padding-bottom: 0 !important;
}
.ps-15 {
    padding-left: 15px !important;
}
.pe-15 {
    padding-right: 15px !important;
}
.pt-15 {
    padding-top: 15px !important;
}
.pb-15 {
    padding-bottom: 15px !important;
}

// Font weight
.fw-bold {
    font-weight: 700 !important;
}
.fw-semibold {
    font-weight: 600 !important;
}
.fw-medium {
    font-weight: 500 !important;
}
.fw-medium-footer {
  font-weight: 500 !important;
  font-size: 12px;
  color: var(--wizodiaPrimaryColor) !important;
  line-height: 13px;
  font-family: Outfit, sans-serif!important;


}
.fw-normal {
    font-weight: 400 !important;
}
.fw-light {
    font-weight: 300 !important;
}

// Font style
.fst-italic {
    font-style: italic !important;
}
.fst-normal {
    font-style: normal !important;
}

// Font Size
.fs-25 {
    font-size: 25px;
}
.fs-20 {
    font-size: 20px;
}
.fs-18 {
    font-size: 18px;
}

// Position
.position-static {
    position: static !important;
}
.position-relative {
    position: relative !important;
}
.position-absolute {
    position: absolute !important;
}
.position-fixed {
    position: fixed !important;
}
.position-sticky {
    position: sticky !important;
}

// Box shadow
.box-shadow {
    box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07) !important;
}

// Text Decoration
.text-decoration-none {
    text-decoration: none !important;
}
.text-decoration-underline {
    text-decoration: underline !important;
}

// Row
.row {
    display: flex;
    flex-wrap: wrap;
    margin: {
        left: -12.5px;
        right: -12.5px;
    };
    >* {
        width: 100%;
        flex-shrink: 0;
        max-width: 100%;
        padding: {
            left: 12.5px;
            right: 12.5px;
        };
    }
}

// Columns
.col-12 {
    flex: 0 0 auto;
    width: 100%;
}
.col-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
}
.col-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
}
.col-9 {
    flex: 0 0 auto;
    width: 75%;
}
.col-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
}
.col-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
}
.col-6 {
    flex: 0 0 auto;
    width: 50%;
}
.col-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
}
.col-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
}
.col-3 {
    flex: 0 0 auto;
    width: 25%;
}
.col-2\/5 {
    flex: 0 0 auto;
    width: 20%;
}
.col-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
}
.col-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
}

// Width
.w-auto {
    width: auto !important;
}
.w-100 {
    width: 100% !important;
}
.w-35 {
    width: 35px !important;
}
.w-45 {
    width: 45px !important;
}
.w-55 {
    width: 55px !important;
}
.w-65 {
    width: 65px !important;
}
.w-75 {
    width: 75px !important;
}
.w-85 {
    width: 85px !important;
}
.w-130 {
    width: 130px !important;
}
.w-110 {
    width: 110px !important;
}
.w-90 {
    width: 90px !important;
}
.w-70 {
    width: 70px !important;
}
.w-50 {
    width: 50px !important;
}
.min-w-auto {
    min-width: auto !important;
}

// Height
.h-auto {
    height: auto !important;
}
.h-100 {
    height: 100% !important;
}
.h-35 {
    height: 35px !important;
}
.h-45 {
    height: 45px !important;
}
.h-55 {
    height: 55px !important;
}
.h-65 {
    height: 65px !important;
}
.h-75 {
    height: 75px !important;
}
.h-85 {
    height: 85px !important;
}
.h-130 {
    height: 130px !important;
}
.h-110 {
    height: 110px !important;
}
.h-90 {
    height: 90px !important;
}
.h-70 {
    height: 70px !important;
}
.h-50 {
    height: 50px !important;
}

// Line height
.lh-1 {
    line-height: 1 !important;
}
.lh-35 {
    line-height: 35px !important;
}

// Sizing
.small, small {
    font-size: 70% !important;
}
.lead {
    font-size: 1.25rem !important;
    font-weight: 300 !important;
}

// List
.list-unstyled {
    list-style-type: none !important;
}

// Transition
.transition {
    transition: var(--transition) !important;
}

// Cursor
.cursor-pointer {
    cursor: pointer !important;
}

// Outline
.outline-0 {
    outline: 0 !important;
}

// Order
.order-1 {
    order: 1 !important;
}
.order-2 {
    order: 2 !important;
}
.order-3 {
    order: 3 !important;
}
.order-4 {
    order: 4 !important;
}
.order-5 {
    order: 5 !important;
}
.order-6 {
    order: 6 !important;
}
.order-7 {
    order: 7 !important;
}
.order-8 {
    order: 8 !important;
}
.order-9 {
    order: 9 !important;
}
.order-10 {
    order: 10 !important;
}
.order-11 {
    order: 11 !important;
}
.order-12 {
    order: 12 !important;
}

/* Min width 576px */
@media (min-width: 576px) {

    // Display
    .d-sm-flex {
        display: flex !important;
    }
    .d-sm-none {
        display: none !important;
    }
    .d-sm-inline {
        display: inline !important;
    }
    .d-sm-inline-block {
        display: inline-block !important;
    }
    .d-sm-block {
        display: block !important;
    }
    .d-sm-grid {
        display: grid !important;
    }
    .d-sm-table {
        display: table !important;
    }
    .d-sm-table-cell {
        display: table-cell !important;
    }
    .d-sm-table-row {
        display: table-row !important;
    }
    .d-sm-inline-flex {
        display: inline-flex !important;
    }

    // Columns
    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }
    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-sm-2\/5 {
        flex: 0 0 auto;
        width: 20%;
    }
    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    // Order
    .order-sm-1 {
        order: 1 !important;
    }
    .order-sm-2 {
        order: 2 !important;
    }
    .order-sm-3 {
        order: 3 !important;
    }
    .order-sm-4 {
        order: 4 !important;
    }
    .order-sm-5 {
        order: 5 !important;
    }
    .order-sm-6 {
        order: 6 !important;
    }
    .order-sm-7 {
        order: 7 !important;
    }
    .order-sm-8 {
        order: 8 !important;
    }
    .order-sm-9 {
        order: 9 !important;
    }
    .order-sm-10 {
        order: 10 !important;
    }
    .order-sm-11 {
        order: 11 !important;
    }
    .order-sm-12 {
        order: 12 !important;
    }

}

/* Min width 768px */
@media (min-width: 768px) {

    // Display
    .d-md-flex {
        display: flex !important;
    }
    .d-md-none {
        display: none !important;
    }
    .d-md-inline {
        display: inline !important;
    }
    .d-md-inline-block {
        display: inline-block !important;
    }
    .d-md-block {
        display: block !important;
    }
    .d-md-grid {
        display: grid !important;
    }
    .d-md-table {
        display: table !important;
    }
    .d-md-table-cell {
        display: table-cell !important;
    }
    .d-md-table-row {
        display: table-row !important;
    }
    .d-md-inline-flex {
        display: inline-flex !important;
    }

    // Columns
    .col-md-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .col-md-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }
    .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .col-md-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
    .col-md-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .col-md-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-md-2\/5 {
        flex: 0 0 auto;
        width: 20%;
    }
    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }
    .p-md-25 {
        padding: 25px !important;
    }

    // Height
    .h-100vh {
        height: 100vh !important;
    }

    // Order
    .order-md-1 {
        order: 1 !important;
    }
    .order-md-2 {
        order: 2 !important;
    }
    .order-md-3 {
        order: 3 !important;
    }
    .order-md-4 {
        order: 4 !important;
    }
    .order-md-5 {
        order: 5 !important;
    }
    .order-md-6 {
        order: 6 !important;
    }
    .order-md-7 {
        order: 7 !important;
    }
    .order-md-8 {
        order: 8 !important;
    }
    .order-md-9 {
        order: 9 !important;
    }
    .order-md-10 {
        order: 10 !important;
    }
    .order-md-11 {
        order: 11 !important;
    }
    .order-md-12 {
        order: 12 !important;
    }

}

/* Min width 992px */
@media (min-width: 992px) {

    // Display
    .d-lg-flex {
        display: flex !important;
    }
    .d-lg-none {
        display: none !important;
    }
    .d-lg-inline {
        display: inline !important;
    }
    .d-lg-inline-block {
        display: inline-block !important;
    }
    .d-lg-block {
        display: block !important;
    }
    .d-lg-grid {
        display: grid !important;
    }
    .d-lg-table {
        display: table !important;
    }
    .d-lg-table-cell {
        display: table-cell !important;
    }
    .d-lg-table-row {
        display: table-row !important;
    }
    .d-lg-inline-flex {
        display: inline-flex !important;
    }

    // Columns
    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }
    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-lg-2\/5 {
        flex: 0 0 auto;
        width: 20%;
    }
    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    // Order
    .order-lg-1 {
        order: 1 !important;
    }
    .order-lg-2 {
        order: 2 !important;
    }
    .order-lg-3 {
        order: 3 !important;
    }
    .order-lg-4 {
        order: 4 !important;
    }
    .order-lg-5 {
        order: 5 !important;
    }
    .order-lg-6 {
        order: 6 !important;
    }
    .order-lg-7 {
        order: 7 !important;
    }
    .order-lg-8 {
        order: 8 !important;
    }
    .order-lg-9 {
        order: 9 !important;
    }
    .order-lg-10 {
        order: 10 !important;
    }
    .order-lg-11 {
        order: 11 !important;
    }
    .order-lg-12 {
        order: 12 !important;
    }

}

/* Min width 1200px */
@media (min-width: 1200px) {

    // Display
    .d-xl-flex {
        display: flex !important;
    }
    .d-xl-none {
        display: none !important;
    }
    .d-xl-inline {
        display: inline !important;
    }
    .d-xl-inline-block {
        display: inline-block !important;
    }
    .d-xl-block {
        display: block !important;
    }
    .d-xl-grid {
        display: grid !important;
    }
    .d-xl-table {
        display: table !important;
    }
    .d-xl-table-cell {
        display: table-cell !important;
    }
    .d-xl-table-row {
        display: table-row !important;
    }
    .d-xl-inline-flex {
        display: inline-flex !important;
    }

    // Columns
    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }
    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-xl-2\/5 {
        flex: 0 0 auto;
        width: 20%;
    }
    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    // Order
    .order-xl-1 {
        order: 1 !important;
    }
    .order-xl-2 {
        order: 2 !important;
    }
    .order-xl-3 {
        order: 3 !important;
    }
    .order-xl-4 {
        order: 4 !important;
    }
    .order-xl-5 {
        order: 5 !important;
    }
    .order-xl-6 {
        order: 6 !important;
    }
    .order-xl-7 {
        order: 7 !important;
    }
    .order-xl-8 {
        order: 8 !important;
    }
    .order-xl-9 {
        order: 9 !important;
    }
    .order-xl-10 {
        order: 10 !important;
    }
    .order-xl-11 {
        order: 11 !important;
    }
    .order-xl-12 {
        order: 12 !important;
    }

}

/* Min width 1400px */
@media (min-width: 1400px) {

    // Display
    .d-xxl-flex {
        display: flex !important;
    }
    .d-xxl-none {
        display: none !important;
    }
    .d-xxl-inline {
        display: inline !important;
    }
    .d-xxl-inline-block {
        display: inline-block !important;
    }
    .d-xxl-block {
        display: block !important;
    }
    .d-xxl-grid {
        display: grid !important;
    }
    .d-xxl-table {
        display: table !important;
    }
    .d-xxl-table-cell {
        display: table-cell !important;
    }
    .d-xxl-table-row {
        display: table-row !important;
    }
    .d-xxl-inline-flex {
        display: inline-flex !important;
    }

    // Columns
    .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }
    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-xxl-2\/5 {
        flex: 0 0 auto;
        width: 20%;
    }
    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    // Order
    .order-xxl-1 {
        order: 1 !important;
    }
    .order-xxl-2 {
        order: 2 !important;
    }
    .order-xxl-3 {
        order: 3 !important;
    }
    .order-xxl-4 {
        order: 4 !important;
    }
    .order-xxl-5 {
        order: 5 !important;
    }
    .order-xxl-6 {
        order: 6 !important;
    }
    .order-xxl-7 {
        order: 7 !important;
    }
    .order-xxl-8 {
        order: 8 !important;
    }
    .order-xxl-9 {
        order: 9 !important;
    }
    .order-xxl-10 {
        order: 10 !important;
    }
    .order-xxl-11 {
        order: 11 !important;
    }
    .order-xxl-12 {
        order: 12 !important;
    }

}

/* Min width 1600px */
@media (min-width: 1600px) {

    // Display
    .d-xxxl-flex {
        display: flex !important;
    }
    .d-xxxl-none {
        display: none !important;
    }
    .d-xxxl-inline {
        display: inline !important;
    }
    .d-xxxl-inline-block {
        display: inline-block !important;
    }
    .d-xxxl-block {
        display: block !important;
    }
    .d-xxxl-grid {
        display: grid !important;
    }
    .d-xxxl-table {
        display: table !important;
    }
    .d-xxxl-table-cell {
        display: table-cell !important;
    }
    .d-xxxl-table-row {
        display: table-row !important;
    }
    .d-xxxl-inline-flex {
        display: inline-flex !important;
    }

    // Columns
    .col-xxxl-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .col-xxxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }
    .col-xxxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
    .col-xxxl-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-xxxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }
    .col-xxxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
    .col-xxxl-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-xxxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
    .col-xxxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .col-xxxl-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-xxxl-2\/5 {
        flex: 0 0 auto;
        width: 20%;
    }
    .col-xxxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }
    .col-xxxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    // Order
    .order-xxxl-1 {
        order: 1 !important;
    }
    .order-xxxl-2 {
        order: 2 !important;
    }
    .order-xxxl-3 {
        order: 3 !important;
    }
    .order-xxxl-4 {
        order: 4 !important;
    }
    .order-xxxl-5 {
        order: 5 !important;
    }
    .order-xxxl-6 {
        order: 6 !important;
    }
    .order-xxxl-7 {
        order: 7 !important;
    }
    .order-xxxl-8 {
        order: 8 !important;
    }
    .order-xxxl-9 {
        order: 9 !important;
    }
    .order-xxxl-10 {
        order: 10 !important;
    }
    .order-xxxl-11 {
        order: 11 !important;
    }
    .order-xxxl-12 {
        order: 12 !important;
    }

}
